<template>
    <div class="scenery-more">
        <slideshow :slideshowList="slideshowList" @gotodetail="handleGo" ></slideshow>
        <img class="debris" src="../../static/scenery/debris.png" />
        <div class="scenery-content">
            <div class="title">
                <img class="home-icon" src="../../static/scenery/home.png" alt="" style="width:20;height:20;">
                <span style="margin-right:6px;font-weight:bold" @click="Jump('/')">首页</span>
                >
                <span style="margin-left:6px;" @click="Jump('/about')">走进条子泥</span>
                >
                <span style="margin-left:6px;color:#509AB1">条子泥风光</span>
                <!-- <span style="margin-left:6px;color:#509AB1">精彩视频</span> -->
            </div>
            <p class="image">精彩视频</p>
            <div class="content-box">
                <div class="video" v-for="(item,index) in videoList" :key="index">
                    <span class="video-box"></span>
                    <video
                        style="object-fit: fill;"
                        controls="controls"
                        :src=item.videoPath
                        type="video/mp4"
                    ></video>
                </div>
                <!-- <div class="content-item">
                    <div style="position: relative;height:459px">
                        <img src="../../static/viewMore01.png" alt="">
                        <div class="box-border1"></div>
                    </div>
                    <p class="author">作者：张三（海天一色） </p>
                </div>
                <div class="content-item">
                    <div style="position: relative;height:340px">
                        <img src="../../static/viewMore15.png" alt="">
                        <div class="box-border2"></div>
                    </div>
                    <p class="author">作者：张三（海天一色） </p>
                </div> -->
            </div>
            <el-pagination
                background
                prev-text="上一页"
                next-text="下一页"
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="totalNum">
            </el-pagination>
        </div>
    </div>
</template>
  
<script>
import { slideshow } from '@/api/test/test'
import { tzn_jmspAPI } from '@/api/test/scenery'
export default {
    name:'SceneryMore',
    components:{},
    data(){
        return {
            slideshowList:[],
            // 分页
            currentPage: 1,
            pageSize:15,
            totalNum:null,
            videoList:[],//视频列表
        }
    },
    created(){},
    mounted(){
        //头部轮播图
        slideshow().then(res => {
            this.slideshowList = res
            console.log(this.slideshowList);
        })
        //精美视频
        this.videoData()
    },
    methods:{
        //精美视频
        videoData(){
            tzn_jmspAPI({
                isUse:1,
                size:this.pageSize,
                page:this.currentPage-1,
            }).then(res => {
                this.videoList = res.data
                this.totalNum = res.count
            }) 
        },
        //分页
        handleCurrentChange(val) {
            this.currentPage = val
            this.videoData()
            console.log(`当前页: ${val}`);
        },
        //头部轮播图跳转
        handleGo(jumpPath){
        console.log(jumpPath,'ccc');
        // this.$router.push(jumpPath)
        window.location.href = jumpPath
        },
        //数据格式化
        decodeHTML(str) {
            var s = "";
            if (!str || str.length == 0) return "";
                s = str.replace(/&amp;/g, "&");
                s = s.replace(/&lt;/g, "<");
                s = s.replace(/&gt;/g, ">");
                s = s.replace(/&nbsp;/g, " ");
                s = s.replace(/&#39;/g, "\'");
                s = s.replace(/&quot;/g, "\"");
                return s;
        }
    },
}
</script>

<style scoped>
.scenery-more{
    background: url('../../static/scenery/backgd.png') ;
}
/deep/.el-image{
    width: 100%;
}
/deep/ .el-carousel__indicators{
    bottom: 5%;
}
.debris{
    width: 100%;
    margin-top: -157px;
    z-index: 8;
    background: transparent;
    position: relative;
}
.title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 92px;
    background-image: url('../../static/scenery/box-border.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: left;
    line-height: 92px;
    font-size: 24px;
    letter-spacing: 5px;
}
.home-icon{
    width: 20px;
    height: 20px;
    margin: 0 16px 0 53px;
}
.title>span:hover{
    cursor: pointer;
    color: #509AB1;
}
.image{
    width: 452px;
    height: 250px;
    background-image: url('~static/scenery/img-box.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 250px;
    color: #509AB1;
    margin: 55px auto;
    letter-spacing: 1px;
    font-size: 27px;
    color: #509AB1;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.28);
}

.content-box{
    width: 65%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-left: 50px;
}
.video{
    position: relative;
    margin-bottom: 30px;
    width: 31%;
    height: 355px;
}
.video-box{
    width: 100%;
    height: 100%;
    background-image: url('~static/scenery/video-box.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: -2px;
}
.video>video{
    width: 98%;
    height: 100%;
}
/* .content-item{
    margin: 8px;
    width: 30%;
}
.bird-box{
    position: relative;
    background-repeat: no-repeat;
    background-size: 98% 98%;
}
.bird-box>img{
    display: block;
    width: 100%;
    height: auto;
}
.author{
    width: 100%;
    height:70px;
    font-size: 18px;
    font-weight: bold;
    color: #509AB1;
    line-height: 50px;
    text-align: center;
    background-image: url('~static/scenery/img-author.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
} */

.el-pagination{
    display: flex;
    justify-content: center;
    margin: 18px 0 51px;
}
/deep/ .el-pagination button, .el-pagination span{
    font-size: 16px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #509AB1;
}
/deep/ .el-pagination button:hover{
    color: #509AB1;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover{
    color: #509AB1;
}
</style>
  